import { CSSProperties } from "@mui/styles";
import { useEnvContext } from "context/EnvContext";
import {
  ENV_DEVELOPMENT,
  ENV_PREPROD,
  ENV_PRODUCTION,
  ENV_STAGING,
} from "core/model/config";
import { Env } from "core/types";
import { SelectInput, optionsToSelectOptions } from "ds_legacy/components/SelectInput";

export const EnvSelect = ({
  onChange,
  style,
}: {
  onChange?: () => void;
  style?: CSSProperties;
}) => {
  const { env, setEnv } = useEnvContext();

  return (
    <SelectInput<{ id: Env; label: Env; value: Env }>
      style={style}
      elementName="environment"
      label="Environment"
      value={env}
      onChange={(newValue) => {
        if (newValue !== env) {
          setEnv(newValue);
          onChange?.();
        }
      }}
      options={optionsToSelectOptions([
        ENV_STAGING,
        ENV_PREPROD,
        ENV_DEVELOPMENT,
        ENV_PRODUCTION,
      ])}
    />
  );
};
