import { useProviderSearchContext } from "context/ProviderSearchContext";
import { CSSProperties, useState } from "react";
import { CreateAccountModal } from "./CreateAccountModal";
import { LoginModal } from "./LoginModal";
import { LoginSuccess, SignupSuccess } from "./SuccessModal";
import {
  MODAL_MIN_WIDTH,
  PROVIDER_SEARCH_LOGIN_MODAL,
  PROVIDER_SEARCH_LOGIN_MODAL_SUCCESS,
  PROVIDER_SEARCH_SIGNUP_MODAL,
  PROVIDER_SEARCH_SIGNUP_MODAL_SUCCESS,
  ProviderSearchLoginModalType,
} from "./shared";

export const getDialogProps = ({
  isMobile,
  loginModal,
}: {
  isMobile: boolean;
  loginModal: ProviderSearchLoginModalType;
}): { paperStyle?: CSSProperties } => {
  switch (loginModal) {
    case PROVIDER_SEARCH_SIGNUP_MODAL:
    case PROVIDER_SEARCH_SIGNUP_MODAL_SUCCESS:
    case PROVIDER_SEARCH_LOGIN_MODAL:
    case PROVIDER_SEARCH_LOGIN_MODAL_SUCCESS: {
      return {
        paperStyle: {
          width: isMobile ? "100%" : MODAL_MIN_WIDTH,
        },
      };
    }
    default: {
      return {};
    }
  }
};

export const ProviderSearchLoginModal = () => {
  const { loginModal, setLoginModal } = useProviderSearchContext();
  const [accountEmail, setAccountEmail] = useState<string>();

  const onClose = () => setLoginModal(null);

  switch (loginModal) {
    case PROVIDER_SEARCH_SIGNUP_MODAL:
      return (
        <CreateAccountModal
          onClose={onClose}
          setAccountEmail={setAccountEmail}
        />
      );
    case PROVIDER_SEARCH_SIGNUP_MODAL_SUCCESS:
      return <SignupSuccess onClose={onClose} accountEmail={accountEmail} />;
    case PROVIDER_SEARCH_LOGIN_MODAL:
      return <LoginModal onClose={onClose} />;
    case PROVIDER_SEARCH_LOGIN_MODAL_SUCCESS:
      return <LoginSuccess onClose={onClose} />;
    default:
      return null;
  }
};
