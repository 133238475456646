import { Theme } from "@mui/material";
import { grammaticalList } from "core/model/utils/strings";
import { GetOntologyType, ProviderListing } from "core/types";
import { format, fromUnixTime } from "date-fns";
import { getCardConfig } from "dsl/ecosystems/ProviderSearchListingPage/ProviderSearchCard";
import Translations from "translations/types";
import { COLUMN_TYPE_DEFAULT_STRING, GeneralTableColumn } from ".";

export function columnProviderSearchName<
  Data extends ProviderListing,
>(): GeneralTableColumn<Data, typeof COLUMN_TYPE_DEFAULT_STRING> {
  return {
    title: "Name",
    type: COLUMN_TYPE_DEFAULT_STRING,
    getProps: ({ name }) => ({
      value: name,
    }),
    width: "30%",
  };
}

export function columnProviderSearchLocation<Data extends ProviderListing>({
  translations,
}: {
  translations: Translations;
}): GeneralTableColumn<Data, typeof COLUMN_TYPE_DEFAULT_STRING> {
  return {
    title: translations.search.searchPageTables.columnNames.location,
    type: COLUMN_TYPE_DEFAULT_STRING,
    getProps: ({ address }) => {
      return {
        value:
          address?.address && address?.zip_code && address?.city
            ? `${address.address} ${address.zip_code} ${address.city}`
            : translations.search.searchPageTables.manuallyAssignedWinner
                .dataNotAvailable,
      };
    },
    width: "20%",
  };
}

export function columnProviderSearchFacility<Data extends ProviderListing>({
  getOntology,
  translations,
}: {
  getOntology: GetOntologyType;
  translations: Translations;
}): GeneralTableColumn<Data, typeof COLUMN_TYPE_DEFAULT_STRING> {
  return {
    title: translations.providersearch.listingPage.printColumnTitleFacilities,
    type: COLUMN_TYPE_DEFAULT_STRING,
    getProps: ({ facilities, is_consultant }) => ({
      value: is_consultant
        ? translations.providersearch.listingPage.consultantFacility
        : grammaticalList({
            array: facilities,
            ontologyType: "facility",
            getOntology,
            translations,
          }),
    }),
    width: "30%",
  };
}

export function columnProviderSearchCapacity<Data extends ProviderListing>({
  locale,
  theme,
  translations,
}: {
  locale: Locale;
  theme: Theme;
  translations: Translations;
}): GeneralTableColumn<Data, typeof COLUMN_TYPE_DEFAULT_STRING> {
  return {
    title: translations.acp.careproviderPages.tabs.capacity,
    type: COLUMN_TYPE_DEFAULT_STRING,
    getProps: ({ capacity, is_consultant }) => {
      const { label } = getCardConfig(
        theme,
        translations,
        capacity?.capacity_status,
        is_consultant,
      );

      const capacityUpdatedAtLabel = capacity?.updated_at
        ? `(${translations.providersearch.listingPage.providerCard.capacity.capacityUpdatedAt(
            {
              updatedAt: format(fromUnixTime(capacity?.updated_at), "P", {
                locale,
              }),
            },
          )})`
        : "";
      return {
        value: `${label} ${capacityUpdatedAtLabel}`,
      };
    },
    width: "20%",
  };
}
