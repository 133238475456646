import { SxProps } from "@mui/material";
import { TRACK_EVENTS } from "core/consts";
import { GlossarySection } from "core/types";
import LinkV2, { useExternalLinkLabel } from "ds_legacy/components/LinkV2";
import {
  margin as computedMargin,
  dp,
  important,
  translate,
} from "ds_legacy/materials/metrics";
import {
  Body,
  FONT_SIZE_13,
  FONT_SIZE_14,
} from "ds_legacy/materials/typography";
import { useProvidersearchNavigationUrls } from "dsl/hooks/useNavigationHandlers";
import { ExternalLinkIcon } from "lucide-react";
import { CSSProperties } from "react";
import { useTracking } from "react-tracking";
import { useTranslations } from "translations";
import { TranslationComposition } from "translations/helpers";
import { FunnelSteps } from "../ProviderSearchOnboarding/common";

export const LinktoGlossary = ({
  linkSx,
  section,
  short,
  margin = computedMargin(0, 0.25),
  funnelStep,
}: {
  funnelStep?: FunnelSteps;
  linkSx?: SxProps;
  margin?: CSSProperties["margin"];
  section?: GlossarySection;
  short?: boolean;
}) => {
  const translations = useTranslations();
  const { goToGlossary } = useProvidersearchNavigationUrls();
  const externalLinkLabel = useExternalLinkLabel();
  const { trackEvent } = useTracking();

  const ariaLabel = externalLinkLabel(
    translations.providersearch.glossary.glossaryAriaLabel,
  );

  const handleClick = () => {
    if (funnelStep)
      trackEvent({
        name: TRACK_EVENTS.PROVIDER_SEARCH_FUNNEL_GLOSSARY_CLICKED,
        step: funnelStep,
      });
  };

  if (short) {
    return (
      <LinkV2
        target="_blank"
        to={goToGlossary(section)}
        aria-label={ariaLabel}
        onClick={handleClick}
        sx={{
          fontSize: FONT_SIZE_14,
          margin,
          display: "inline-flex",
          alignItems: "baseline",
          ...linkSx,
        }}
      >
        <ExternalLinkIcon
          fontSize="inherit"
          style={{
            color: "inherit",
            transform: translate({ y: 1 }),
            paddingRight: dp(2),
            height: important(dp(13)),
          }}
          size={FONT_SIZE_13}
        />
        {translations.providersearch.header.glossary}
      </LinkV2>
    );
  }

  return (
    <TranslationComposition
      withOptions
      translations={translations.providersearch.glossary.linkToGlossary}
    >
      {([before, link, after]) => (
        <Body
          as="p"
          margin={margin}
          style={{
            alignSelf: "flex-start",
          }}
        >
          {before}
          <LinkV2
            target="_blank"
            to={goToGlossary(section)}
            onClick={handleClick}
            aria-label={ariaLabel}
            sx={{
              whiteSpace: "nowrap",
              margin: computedMargin(0, 0.25),
              display: "inline-flex",
              alignItems: "baseline",
              ...linkSx,
            }}
          >
            <ExternalLinkIcon
              fontSize="inherit"
              style={{
                color: "inherit",
                transform: translate({ y: 1 }),
                paddingRight: dp(2),
                height: important(dp(13)),
              }}
              size={FONT_SIZE_13}
            />
            {link}
          </LinkV2>
          {after}
        </Body>
      )}
    </TranslationComposition>
  );
};
