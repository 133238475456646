import { useGetProviderSearchRedirectLink } from "dsl/hooks/useGetProviderSearchRedirectLink";
import {
  USER_FILTER_WITH_CONSULTANTS,
  USER_FILTER_WITH_PROVIDERS,
} from "dsl/organisms/Filters/consts";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ConsultantProviderSelection } from "./Pages/SearchType";
import { Services } from "./Pages/Services";
import { Solutions } from "./Pages/Solutions";
import { StartDate } from "./Pages/StartDate";
import { Zipcode } from "./Pages/Zipcode";
import {
  FunnelFormData,
  FunnelSteps,
  STEP_CONSULTANT_PROVIDER_SELECTION,
  STEP_SERVICES,
  STEP_SOLUTIONS,
  STEP_START_DATE,
  STEP_ZIPCODE,
  StepperPage,
} from "./common";

const initialStateProviderSearchFunnel: FunnelFormData = {
  [USER_FILTER_WITH_CONSULTANTS]: false,
  [USER_FILTER_WITH_PROVIDERS]: false,
};

export default function ProviderSearchOnboarding() {
  const [currentStep, setCurrentStep] = useState<FunnelSteps>(1);
  const [formData, setFormData] = useState<FunnelFormData>(
    initialStateProviderSearchFunnel,
  );
  const navigate = useNavigate();
  const getRedirectLink = useGetProviderSearchRedirectLink();

  const renderStepContent = () => {
    switch (currentStep) {
      case STEP_ZIPCODE:
        return (
          <Zipcode
            formData={formData}
            setCurrentStep={setCurrentStep}
            setFormData={setFormData}
          />
        );
      case STEP_CONSULTANT_PROVIDER_SELECTION:
        return (
          <ConsultantProviderSelection
            formData={formData}
            onSubmit={(data: FunnelFormData) => handleSubmit(data)}
            setCurrentStep={setCurrentStep}
            setFormData={setFormData}
          />
        );
      case STEP_SOLUTIONS:
        return (
          <Solutions
            formData={formData}
            setCurrentStep={setCurrentStep}
            setFormData={setFormData}
          />
        );
      case STEP_SERVICES:
        return (
          <Services
            formData={formData}
            setCurrentStep={setCurrentStep}
            setFormData={setFormData}
          />
        );
      case STEP_START_DATE:
        return (
          <StartDate
            formData={formData}
            onSubmit={(data: FunnelFormData) => handleSubmit(data)}
            setCurrentStep={setCurrentStep}
            setFormData={setFormData}
          />
        );
      default:
        return null;
    }
  };

  const handleSubmit = (data: FunnelFormData) => {
    setFormData((prevData: FunnelFormData) => {
      const updatedData = {
        ...prevData,
        ...data,
      };
      navigate(getRedirectLink({ formData: updatedData }));
      return updatedData;
    });
  };

  return <StepperPage>{renderStepContent()}</StepperPage>;
}
