import { useEffect, useRef, useState } from "react";

export default function useFocusWithin<T extends HTMLElement>() {
  const parentRef = useRef<T>(null);
  const [isFocusWithin, setIsFocusWithin] = useState<boolean>(false);

  useEffect(() => {
    function hasFocusWithin(): boolean {
      if (!parentRef.current) return false;
      return parentRef.current.contains(document.activeElement);
    }

    function handler() {
      setIsFocusWithin(hasFocusWithin());
    }

    document.addEventListener("focusin", handler, false);
    return () => {
      document.removeEventListener("focusin", handler, false);
    };
  }, []);

  return { isFocusWithin, parentRef };
}
